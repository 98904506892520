var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.viewMode
        ? _c("asyent-form-display", {
            attrs: {
              value: _vm.challengeValue,
              "no-label": _vm.noLabel,
              label: _vm.label,
            },
          })
        : _vm._e(),
      !_vm.noLabel && !_vm.viewMode
        ? _c("div", [
            _c(
              "span",
              {
                class: { "field-mandatory": _vm.mandatory },
                staticStyle: { "font-size": "0.85rem" },
              },
              [_vm._v(_vm._s(_vm.label))]
            ),
          ])
        : _vm._e(),
      !_vm.viewMode
        ? _c("ValidationProvider", {
            attrs: {
              name: _vm.label,
              rules: _vm.formFieldRules,
              vid: _vm.name,
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function (ref) {
                    var errors = ref.errors
                    return [
                      _c(
                        "v-text-field",
                        _vm._g(
                          _vm._b(
                            {
                              staticClass: "mt-2",
                              attrs: {
                                "aria-label": _vm.label,
                                disabled: _vm.formFieldDisabled,
                                "error-messages": _vm.formFieldErrors(errors),
                                hint:
                                  !_vm.isChallengeDisabled &&
                                  _vm.challengeValue &&
                                  !_vm.readonly &&
                                  !_vm.formFieldDisabled &&
                                  !_vm.validated
                                    ? _vm.ae$lng(
                                        "forms.challenge.clickOnShield"
                                      )
                                    : "",
                                success: _vm.validated,
                                clearable: "",
                                counter: "",
                                label: "",
                                "persistent-hint": "",
                                dense: _vm.appConfig.forms.dense,
                                flat: _vm.appConfig.forms.flat,
                                solo: _vm.appConfig.forms.solo,
                                "solo-inverted":
                                  _vm.appConfig.forms.soloInverted,
                                filled: _vm.appConfig.forms.filled,
                                outlined: _vm.appConfig.forms.outlined,
                              },
                              on: { input: _vm.validateDisabledChallenge },
                              scopedSlots: _vm._u(
                                [
                                  !_vm.isChallengeDisabled &&
                                  _vm.challengeValue &&
                                  !_vm.readonly &&
                                  !_vm.formFieldDisabled
                                    ? {
                                        key: "append",
                                        fn: function () {
                                          return [
                                            _c(
                                              "v-dialog",
                                              {
                                                attrs: {
                                                  fullscreen:
                                                    _vm.$vuetify.breakpoint
                                                      .smAndDown,
                                                  "max-width": "500",
                                                  persistent: "",
                                                  scrollable: "",
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "activator",
                                                      fn: function (ref) {
                                                        var on = ref.on
                                                        return [
                                                          _c(
                                                            "v-icon",
                                                            _vm._g({}, on),
                                                            [
                                                              _vm._v(
                                                                "mdi-shield-edit-outline"
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  true
                                                ),
                                                model: {
                                                  value: _vm.dialog,
                                                  callback: function ($$v) {
                                                    _vm.dialog = $$v
                                                  },
                                                  expression: "dialog",
                                                },
                                              },
                                              [
                                                _c(
                                                  "v-card",
                                                  {
                                                    staticClass:
                                                      "mx-auto fill-height",
                                                    attrs: {
                                                      "max-width": "500",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "v-toolbar",
                                                      {
                                                        attrs: {
                                                          color: "primary",
                                                          dark: "",
                                                          "max-height": "56",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "v-toolbar-title",
                                                          [
                                                            _c(
                                                              "v-icon",
                                                              {
                                                                attrs: {
                                                                  large: "",
                                                                  left: "",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " mdi-shield-edit-outline"
                                                                ),
                                                              ]
                                                            ),
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.ae$lng(
                                                                    "forms.challenge.confirmationCode"
                                                                  )
                                                                ) +
                                                                " "
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _c("v-spacer"),
                                                        !_vm.loading &&
                                                        _vm.tmpCode &&
                                                        _vm.tmpHash
                                                          ? _c(
                                                              "asyent-button",
                                                              {
                                                                attrs: {
                                                                  "button-icon":
                                                                    "mdi-check",
                                                                  "button-tooltip":
                                                                    _vm.ae$lng(
                                                                      "forms.challenge.save"
                                                                    ),
                                                                  icon: "",
                                                                  disabled:
                                                                    _vm.loading,
                                                                  loading:
                                                                    _vm.loading,
                                                                  "requires-confirmation":
                                                                    "",
                                                                },
                                                                on: {
                                                                  confirmed:
                                                                    _vm.validate,
                                                                },
                                                              }
                                                            )
                                                          : _vm._e(),
                                                        _c("asyent-button", {
                                                          attrs: {
                                                            "button-icon":
                                                              "mdi-close",
                                                            "button-tooltip":
                                                              _vm.ae$lng(
                                                                "forms.challenge.cancel"
                                                              ),
                                                            icon: "",
                                                            "requires-confirmation":
                                                              "",
                                                          },
                                                          on: {
                                                            confirmed:
                                                              _vm.cancel,
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "v-card-text",
                                                      {
                                                        staticClass:
                                                          "text-justify",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass: "mt-5",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.ae$lng(
                                                                    "forms.challenge.pleaseClickOn"
                                                                  )
                                                                ) +
                                                                " "
                                                            ),
                                                            _c("v-icon", [
                                                              _vm._v(
                                                                "mdi-shield-check-outline"
                                                              ),
                                                            ]),
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.ae$lng(
                                                                    "forms.challenge.challengeMessage1"
                                                                  )
                                                                ) +
                                                                " "
                                                            ),
                                                            _c("v-icon", [
                                                              _vm._v(
                                                                "mdi-check"
                                                              ),
                                                            ]),
                                                            _vm._v(" : "),
                                                            _c("b", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.challengeValue
                                                                )
                                                              ),
                                                            ]),
                                                          ],
                                                          1
                                                        ),
                                                        _c("v-text-field", {
                                                          staticClass: "mt-5",
                                                          attrs: {
                                                            "error-messages":
                                                              _vm.challengeErrors,
                                                            dense: "",
                                                            label: _vm.ae$lng(
                                                              "forms.challenge.pleaseEnterCode"
                                                            ),
                                                            solo: "",
                                                            type: "text",
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "prepend-inner",
                                                                fn: function () {
                                                                  return [
                                                                    _c(
                                                                      "v-btn",
                                                                      {
                                                                        attrs: {
                                                                          icon: "",
                                                                        },
                                                                      },
                                                                      [
                                                                        true
                                                                          ? _c(
                                                                              "asyent-button",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    disabled:
                                                                                      _vm.loading,
                                                                                    loading:
                                                                                      _vm.loading,
                                                                                    "aria-label":
                                                                                      _vm.ae$lng(
                                                                                        "forms.challenge.sendResendCode"
                                                                                      ),
                                                                                    "button-icon":
                                                                                      "mdi-shield-check-outline",
                                                                                    "button-tooltip":
                                                                                      _vm.ae$lng(
                                                                                        "forms.challenge.sendResendCode"
                                                                                      ),
                                                                                    icon: "",
                                                                                    "requires-confirmation":
                                                                                      "",
                                                                                  },
                                                                                on: {
                                                                                  confirmed:
                                                                                    _vm.issue,
                                                                                },
                                                                              }
                                                                            )
                                                                          : _vm._e(),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ]
                                                                },
                                                                proxy: true,
                                                              },
                                                            ],
                                                            null,
                                                            true
                                                          ),
                                                          model: {
                                                            value: _vm.tmpCode,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.tmpCode = $$v
                                                            },
                                                            expression:
                                                              "tmpCode",
                                                          },
                                                        }),
                                                        _c("div", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.ae$lng(
                                                                "forms.challenge.challengeMessage2"
                                                              )
                                                            )
                                                          ),
                                                        ]),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      }
                                    : null,
                                  {
                                    key: "append-outer",
                                    fn: function () {
                                      return [_vm._t("append-outer")]
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                true
                              ),
                              model: {
                                value: _vm.challengeValue,
                                callback: function ($$v) {
                                  _vm.challengeValue = $$v
                                },
                                expression: "challengeValue",
                              },
                            },
                            "v-text-field",
                            _vm.$attrs,
                            false
                          ),
                          _vm.$listeners
                        )
                      ),
                    ]
                  },
                },
              ],
              null,
              false,
              1900198602
            ),
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }