var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.widget
    ? _c(
        "v-card",
        {
          staticClass: "pa-0 ma-0 mx-auto",
          staticStyle: { overflow: "hidden", height: "100%" },
          attrs: {
            color: "transparent",
            flat: "",
            elevation: "0",
            rounded: false,
          },
        },
        [
          !_vm.noHeader && !_vm.widget
            ? _c(
                "v-toolbar",
                {
                  directives: [
                    {
                      name: "scroll",
                      rawName: "v-scroll:#scroll-target",
                      value: _vm.onScroll,
                      expression: "onScroll",
                      arg: "#scroll-target",
                    },
                  ],
                  class:
                    "" +
                    (_vm.raiseBar > 0
                      ? "ma-3"
                      : _vm.$store.state.ui.maximizedShiftKey
                      ? ""
                      : !_vm.appConfig.window.transparent
                      ? ""
                      : "mt-3"),
                  attrs: {
                    color: _vm.$store.state.ui.maximizedShiftKey
                      ? "primary"
                      : _vm.appConfig.window.transparent && !_vm.raiseBar > 0
                      ? "transparent"
                      : "systemBar",
                    dark: _vm.$store.state.ui.maximizedShiftKey,
                    height:
                      _vm.appConfig.window.barHeight +
                      (_vm.raiseBar > 0
                        ? _vm.$vuetify.breakpoint.smAndDown
                          ? 0
                          : 0
                        : 0),
                    elevation: _vm.$store.state.ui.maximizedShiftKey
                      ? 3
                      : _vm.raiseBar,
                  },
                },
                [
                  _c(
                    "v-toolbar-title",
                    [
                      _c(
                        "v-list-item",
                        { staticClass: "pl-0" },
                        [
                          _vm.titleIcon &&
                          _vm.titleIcon.length > 0 &&
                          !_vm.titleIconImage
                            ? _c(
                                "v-list-item-avatar",
                                {
                                  attrs: {
                                    size: _vm.appConfig.window.avatarSize[0],
                                    color:
                                      _vm.titleIconColor ||
                                      _vm.appConfig.window.avatarColor,
                                  },
                                },
                                [
                                  _c("v-icon", {
                                    attrs: {
                                      size: _vm.appConfig.window.avatarSize[1],
                                      dark:
                                        _vm.titleIconDark === undefined
                                          ? _vm.appConfig.window.avatarColorDark
                                          : _vm.titleIconDark === "Y",
                                    },
                                    domProps: {
                                      textContent: _vm._s(_vm.titleIcon),
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.titleIcon &&
                          _vm.titleIcon.length > 0 &&
                          _vm.titleIconImage
                            ? _c("v-img", {
                                attrs: {
                                  height: _vm.appConfig.window.avatarSize[0],
                                  "max-height":
                                    _vm.appConfig.window.avatarSize[0],
                                  position: "left left",
                                  src: _vm.titleIcon,
                                },
                              })
                            : _vm._e(),
                          _c(
                            "v-list-item-content",
                            [
                              _vm.title && _vm.title.length > 0 && !_vm.noTitle
                                ? _c(
                                    "v-list-item-title",
                                    [
                                      _c("asyent-toc-heading", {
                                        attrs: {
                                          caption: _vm.title,
                                          level: 1,
                                          title: "",
                                        },
                                      }),
                                      _c("span", { staticClass: "title" }, [
                                        _vm._v(" " + _vm._s(_vm.title) + " "),
                                      ]),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              !_vm.noBreadcrumbs &&
                              _vm.pagePath.length > 0 &&
                              _vm.$vuetify.breakpoint.smAndUp
                                ? _c(
                                    "v-list-item-subtitle",
                                    [
                                      _c("v-breadcrumbs", {
                                        staticClass:
                                          "pa-0 pl-0 pt-1 font-weight-regular d-inline",
                                        attrs: {
                                          items: _vm.pagePath,
                                          divider: "/",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("v-spacer"),
                  _vm._t("header-filter"),
                  _vm.appConfig.window.formActions.visible &&
                  _vm.formActionsFiltered.length > 0 &&
                  _vm.$vuetify.breakpoint.mdAndUp
                    ? _c(
                        "v-menu",
                        {
                          attrs: {
                            bottom: "",
                            "open-on-click": "",
                            "close-on-content-click": "",
                            "close-on-click": "",
                            "offset-y": "",
                            tile: "",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function (ref) {
                                  var on = ref.on
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        {
                                          attrs: {
                                            icon: !_vm.$vuetify.breakpoint
                                              .mdAndUp,
                                            "aria-label":
                                              _vm.ae$lng("page.formActions"),
                                            text: _vm.$vuetify.breakpoint
                                              .mdAndUp,
                                          },
                                        },
                                        on
                                      ),
                                      [
                                        _c(
                                          "v-icon",
                                          {
                                            attrs: {
                                              color:
                                                _vm.appConfig.window.formActions
                                                  .color,
                                              left: _vm.$vuetify.breakpoint
                                                .mdAndUp,
                                            },
                                          },
                                          [_vm._v("mdi-eye-settings")]
                                        ),
                                        _vm.$vuetify.breakpoint.mdAndUp
                                          ? [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "text-capitalize",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.ae$lng(
                                                        "page.formActions"
                                                      )
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ]
                                          : _vm._e(),
                                      ],
                                      2
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            4066484067
                          ),
                        },
                        [
                          _c(
                            "v-list",
                            { attrs: { dense: "" } },
                            [
                              _vm._l(
                                _vm.formActionsFiltered,
                                function (action) {
                                  return [
                                    _vm.isAuthorized(action)
                                      ? _c("asyent-button", {
                                          key: action.name,
                                          attrs: {
                                            "button-icon": action["icon"],
                                            "button-tooltip": action["tooltip"],
                                            "requires-confirmation":
                                              action["action"],
                                            text: "",
                                            "as-list-item": "",
                                            "dense-list": "",
                                          },
                                          on: {
                                            confirmed: function ($event) {
                                              return _vm.formActionMethod(
                                                action
                                              )
                                            },
                                          },
                                        })
                                      : _vm._e(),
                                  ]
                                }
                              ),
                            ],
                            2
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._l(_vm.toolbarActionsFiltered, function (action) {
                    return _c(
                      "v-tooltip",
                      {
                        key: action.name,
                        attrs: { bottom: "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function (ref) {
                                var on = ref.on
                                return [
                                  _c(
                                    "v-btn",
                                    _vm._g(
                                      {
                                        attrs: {
                                          "aria-label": action.tooltip,
                                          icon: !_vm.$vuetify.breakpoint
                                            .mdAndUp,
                                          text: _vm.$vuetify.breakpoint.mdAndUp,
                                        },
                                        on: {
                                          click: function ($event) {
                                            if (
                                              $event.ctrlKey ||
                                              $event.shiftKey ||
                                              $event.altKey ||
                                              $event.metaKey
                                            ) {
                                              return null
                                            }
                                            return _vm.$emit(
                                              "toolbar-action",
                                              action.name
                                            )
                                          },
                                        },
                                      },
                                      on
                                    ),
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          attrs: {
                                            color:
                                              _vm.appConfig.window
                                                .toolbarActions.color,
                                            left: _vm.$vuetify.breakpoint
                                              .mdAndUp,
                                          },
                                        },
                                        [_vm._v(_vm._s(action.icon))]
                                      ),
                                      _vm.$vuetify.breakpoint.mdAndUp
                                        ? [
                                            _c(
                                              "span",
                                              {
                                                staticClass: "text-capitalize",
                                              },
                                              [_vm._v(_vm._s(action.tooltip))]
                                            ),
                                          ]
                                        : _vm._e(),
                                    ],
                                    2
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      },
                      [_c("span", [_vm._v(_vm._s(action.tooltip))])]
                    )
                  }),
                  _vm.headerActionsFiltered &&
                  _vm.headerActionsFiltered.length >
                    _vm.appConfig.window.headerActions.limit
                    ? _c(
                        "v-menu",
                        {
                          attrs: { bottom: "", "offset-y": "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function (ref) {
                                  var on = ref.on
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        {
                                          attrs: {
                                            icon: !_vm.$vuetify.breakpoint
                                              .mdAndUp,
                                            "aria-label":
                                              _vm.ae$lng("page.moreActions"),
                                            text: _vm.$vuetify.breakpoint
                                              .mdAndUp,
                                          },
                                        },
                                        on
                                      ),
                                      [
                                        _c(
                                          "v-icon",
                                          {
                                            attrs: {
                                              color:
                                                _vm.appConfig.window
                                                  .headerActions.color,
                                              left: _vm.$vuetify.breakpoint
                                                .mdAndUp,
                                            },
                                          },
                                          [_vm._v("mdi-menu")]
                                        ),
                                        _vm.$vuetify.breakpoint.mdAndUp
                                          ? [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "text-capitalize",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.ae$lng(
                                                        "page.moreActions"
                                                      )
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ]
                                          : _vm._e(),
                                      ],
                                      2
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            123736424
                          ),
                        },
                        [
                          _c(
                            "v-list",
                            { attrs: { dense: "" } },
                            [
                              _vm._l(
                                _vm.headerActionsFiltered,
                                function (action, a) {
                                  return [
                                    "link" === action.type
                                      ? [
                                          _c(
                                            "v-list-item",
                                            {
                                              key: a,
                                              attrs: {
                                                to: _vm.headerLinkMethod(
                                                  action.name
                                                ),
                                                dense: "",
                                              },
                                            },
                                            [
                                              _c("v-list-item-title", [
                                                _vm._v(_vm._s(action.tooltip)),
                                              ]),
                                              _c(
                                                "v-list-item-icon",
                                                [
                                                  _c("v-icon", [
                                                    _vm._v(_vm._s(action.icon)),
                                                  ]),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      : "slink" === action.type
                                      ? [
                                          _c(
                                            "v-list-item",
                                            {
                                              key: a,
                                              attrs: {
                                                to: action.link,
                                                dense: "",
                                              },
                                            },
                                            [
                                              _c(
                                                "v-list-item-icon",
                                                [
                                                  _c("v-icon", [
                                                    _vm._v(_vm._s(action.icon)),
                                                  ]),
                                                ],
                                                1
                                              ),
                                              _c("v-list-item-title", [
                                                _vm._v(_vm._s(action.tooltip)),
                                              ]),
                                            ],
                                            1
                                          ),
                                        ]
                                      : [
                                          _c(
                                            "v-list-item",
                                            {
                                              key: a,
                                              attrs: { dense: "" },
                                              on: {
                                                click: function ($event) {
                                                  if (
                                                    $event.ctrlKey ||
                                                    $event.shiftKey ||
                                                    $event.altKey ||
                                                    $event.metaKey
                                                  ) {
                                                    return null
                                                  }
                                                  return _vm.$emit(
                                                    "header-action",
                                                    action.name
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "v-list-item-icon",
                                                [
                                                  _c("v-icon", [
                                                    _vm._v(_vm._s(action.icon)),
                                                  ]),
                                                ],
                                                1
                                              ),
                                              _c("v-list-item-title", [
                                                _vm._v(_vm._s(action.tooltip)),
                                              ]),
                                            ],
                                            1
                                          ),
                                        ],
                                  ]
                                }
                              ),
                            ],
                            2
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.headerActionsFiltered &&
                  _vm.headerActionsFiltered.length ===
                    _vm.appConfig.window.headerActions.limit
                    ? _vm._l(_vm.headerActionsFiltered, function (action) {
                        return _c(
                          "v-tooltip",
                          {
                            key: action.name,
                            attrs: { bottom: "" },
                            scopedSlots: _vm._u(
                              [
                                "link" === action.type
                                  ? {
                                      key: "activator",
                                      fn: function (ref) {
                                        var on = ref.on
                                        return [
                                          _c(
                                            "v-btn",
                                            _vm._g(
                                              {
                                                attrs: {
                                                  "aria-label": action.tooltip,
                                                  icon: !_vm.$vuetify.breakpoint
                                                    .mdAndUp,
                                                  text: _vm.$vuetify.breakpoint
                                                    .mdAndUp,
                                                  to: _vm.headerLinkMethod(
                                                    action.name
                                                  ),
                                                },
                                              },
                                              on
                                            ),
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  attrs: {
                                                    color:
                                                      _vm.appConfig.window
                                                        .headerActions.color,
                                                    left: _vm.$vuetify
                                                      .breakpoint.mdAndUp,
                                                  },
                                                },
                                                [_vm._v(_vm._s(action.icon))]
                                              ),
                                              _vm.$vuetify.breakpoint.mdAndUp
                                                ? [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "text-capitalize",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(action.tooltip)
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                : _vm._e(),
                                            ],
                                            2
                                          ),
                                        ]
                                      },
                                    }
                                  : "slink" === action.type
                                  ? {
                                      key: "activator",
                                      fn: function (ref) {
                                        var on = ref.on
                                        return [
                                          _c(
                                            "v-btn",
                                            _vm._g(
                                              {
                                                attrs: {
                                                  "aria-label": action.tooltip,
                                                  icon: !_vm.$vuetify.breakpoint
                                                    .mdAndUp,
                                                  text: _vm.$vuetify.breakpoint
                                                    .mdAndUp,
                                                  to: action.link,
                                                },
                                              },
                                              on
                                            ),
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  attrs: {
                                                    color:
                                                      _vm.appConfig.window
                                                        .headerActions.color,
                                                    left: _vm.$vuetify
                                                      .breakpoint.mdAndUp,
                                                  },
                                                },
                                                [_vm._v(_vm._s(action.icon))]
                                              ),
                                              _vm.$vuetify.breakpoint.mdAndUp
                                                ? [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "text-capitalize",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(action.tooltip)
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                : _vm._e(),
                                            ],
                                            2
                                          ),
                                        ]
                                      },
                                    }
                                  : {
                                      key: "activator",
                                      fn: function (ref) {
                                        var on = ref.on
                                        return [
                                          _c(
                                            "v-btn",
                                            _vm._g(
                                              {
                                                attrs: {
                                                  "aria-label": action.tooltip,
                                                  icon: !_vm.$vuetify.breakpoint
                                                    .mdAndUp,
                                                  text: _vm.$vuetify.breakpoint
                                                    .mdAndUp,
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    if (
                                                      $event.ctrlKey ||
                                                      $event.shiftKey ||
                                                      $event.altKey ||
                                                      $event.metaKey
                                                    ) {
                                                      return null
                                                    }
                                                    return _vm.$emit(
                                                      "header-action",
                                                      action.name
                                                    )
                                                  },
                                                },
                                              },
                                              on
                                            ),
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  attrs: {
                                                    color:
                                                      _vm.appConfig.window
                                                        .headerActions.color,
                                                    left: _vm.$vuetify
                                                      .breakpoint.mdAndUp,
                                                  },
                                                },
                                                [_vm._v(_vm._s(action.icon))]
                                              ),
                                              _vm.$vuetify.breakpoint.mdAndUp
                                                ? [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "text-capitalize",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(action.tooltip)
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                : _vm._e(),
                                            ],
                                            2
                                          ),
                                        ]
                                      },
                                    },
                              ],
                              null,
                              true
                            ),
                          },
                          [_c("span", [_vm._v(_vm._s(action.tooltip))])]
                        )
                      })
                    : _vm._e(),
                  _vm.appConfig.window.tools.resize &&
                  !_vm.maximized &&
                  !_vm.fullWidth &&
                  !_vm.noWindowControls &&
                  _vm.$vuetify.breakpoint.mdAndUp
                    ? _c(
                        "v-tooltip",
                        {
                          attrs: { bottom: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function (ref) {
                                  var on = ref.on
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        {
                                          attrs: {
                                            "aria-label":
                                              _vm.ae$lng("pageBar.resize"),
                                            icon: "",
                                          },
                                          on: {
                                            click: function ($event) {
                                              if (
                                                $event.ctrlKey ||
                                                $event.shiftKey ||
                                                $event.altKey ||
                                                $event.metaKey
                                              ) {
                                                return null
                                              }
                                              return _vm.resizeWindow.apply(
                                                null,
                                                arguments
                                              )
                                            },
                                          },
                                        },
                                        on
                                      ),
                                      [
                                        _c("v-icon", [
                                          _vm._v("mdi-arrow-expand-horizontal"),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            884564419
                          ),
                        },
                        [
                          _c("span", [
                            _vm._v(_vm._s(_vm.ae$lng("pageBar.resize"))),
                          ]),
                        ]
                      )
                    : _vm._e(),
                  _vm.appConfig.window.tools.maximize &&
                  !_vm.standalone &&
                  !_vm.fullWidth &&
                  !_vm.noWindowControls &&
                  _vm.$vuetify.breakpoint.mdAndUp
                    ? _c(
                        "v-tooltip",
                        {
                          attrs: { bottom: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function (ref) {
                                  var on = ref.on
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        {
                                          attrs: {
                                            "aria-label": _vm.maximized
                                              ? _vm.ae$lng("pageBar.minimize")
                                              : _vm.ae$lng("pageBar.maximize"),
                                            icon: "",
                                            disabled: _vm.resize >= _vm.maxSize,
                                          },
                                          on: {
                                            click: [
                                              function ($event) {
                                                if (
                                                  $event.ctrlKey ||
                                                  $event.shiftKey ||
                                                  $event.altKey ||
                                                  $event.metaKey
                                                ) {
                                                  return null
                                                }
                                                return _vm.transformWindow.apply(
                                                  null,
                                                  arguments
                                                )
                                              },
                                              function ($event) {
                                                if (!$event.shiftKey) {
                                                  return null
                                                }
                                                return _vm.transformWindowShift.apply(
                                                  null,
                                                  arguments
                                                )
                                              },
                                            ],
                                          },
                                        },
                                        on
                                      ),
                                      [
                                        _vm.maximized
                                          ? _c("v-icon", [
                                              _vm._v("mdi-window-minimize"),
                                            ])
                                          : _c("v-icon", [
                                              _vm._v("mdi-window-maximize"),
                                            ]),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            4043899222
                          ),
                        },
                        [
                          _vm.maximized
                            ? _c("span", [
                                _vm._v(_vm._s(_vm.ae$lng("pageBar.minimize"))),
                              ])
                            : _c("span", [
                                _vm._v(_vm._s(_vm.ae$lng("pageBar.maximize"))),
                              ]),
                        ]
                      )
                    : _vm._e(),
                ],
                2
              )
            : _vm._e(),
          _c(
            "v-card-text",
            {
              staticClass: "pa-0 ma-0",
              style:
                "overflow: auto; position: absolute; bottom: 0; top: " +
                (!_vm.widget
                  ? _vm.appConfig.window.barHeight + (_vm.raiseBar > 0 ? 20 : 5)
                  : 0) +
                "px;",
              attrs: { id: "scroll-target" },
            },
            [
              !_vm.widget
                ? _c(
                    "v-container",
                    { attrs: { fluid: "" } },
                    [
                      _c(
                        "v-row",
                        { attrs: { "no-gutters": "" } },
                        [
                          _c(
                            "v-col",
                            _vm._b(
                              {
                                class: {
                                  "ml-auto": _vm.centered,
                                  "mr-auto": _vm.centered,
                                },
                              },
                              "v-col",
                              _vm.columnsFiltered,
                              false
                            ),
                            [_vm._t("default")],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.widget ? _vm._t("default") : _vm._e(),
            ],
            2
          ),
          _vm.formActionsFiltered.length > 0 &&
          _vm.$vuetify.breakpoint.smAndDown
            ? _c(
                "v-menu",
                {
                  staticStyle: { "z-index": "99" },
                  attrs: {
                    top: "",
                    "offset-y": "",
                    "offset-x": "",
                    "open-on-click": "",
                    "close-on-content-click": "",
                    "offset-overflow": "",
                    tile: "",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function (ref) {
                          var on = ref.on
                          return [
                            _c(
                              "v-btn",
                              _vm._g(
                                {
                                  staticClass: "fab-action-btn",
                                  staticStyle: { "z-index": "99" },
                                  attrs: {
                                    "aria-label":
                                      _vm.ae$lng("page.formActions"),
                                    bottom: "",
                                    color: "red",
                                    dark: "",
                                    fab: "",
                                    fixed: "",
                                    width:
                                      _vm.appConfig.navigation.fabIconSize[0] +
                                      "px",
                                    height:
                                      _vm.appConfig.navigation.fabIconSize[0] +
                                      "px",
                                    right: "",
                                  },
                                },
                                on
                              ),
                              [
                                _c(
                                  "v-icon",
                                  {
                                    attrs: {
                                      size: _vm.appConfig.navigation
                                        .fabIconSize[1],
                                    },
                                  },
                                  [_vm._v("mdi-eye-settings")]
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    519635971
                  ),
                },
                [
                  _c(
                    "v-list",
                    { attrs: { dense: "" } },
                    [
                      _vm._l(_vm.formActionsFiltered, function (action) {
                        return [
                          _vm.isAuthorized(action)
                            ? _c("asyent-button", {
                                key: action.name,
                                attrs: {
                                  "button-icon": action["icon"],
                                  "button-tooltip": action["tooltip"],
                                  "requires-confirmation": action["action"],
                                  text: "",
                                  "as-list-item": "",
                                  "dense-list": "",
                                },
                                on: {
                                  confirmed: function ($event) {
                                    return _vm.formActionMethod(action)
                                  },
                                },
                              })
                            : _vm._e(),
                        ]
                      }),
                    ],
                    2
                  ),
                ],
                1
              )
            : _vm._e(),
          !_vm.widget &&
          _vm.appConfig.toc.actions > 0 &&
          _vm.formActionsFiltered.length > 0 &&
          _vm.$vuetify.breakpoint.mdAndUp
            ? _c(
                "Portal",
                { attrs: { to: "page-tools" } },
                [
                  _vm.formActionsFiltered.length > 0 &&
                  _vm.$vuetify.breakpoint.mdAndUp
                    ? [
                        _c(
                          "v-list",
                          {
                            staticClass: "pt-2 pb-0 mt-0 mb-0",
                            staticStyle: { overflow: "auto" },
                            style:
                              "" +
                              (_vm.formActionsFiltered.length >
                              _vm.appConfig.toc.actions
                                ? "border-bottom: 1px dashed var(--v-primary-base)"
                                : ""),
                            attrs: {
                              dense: "",
                              "max-height":
                                _vm.appConfig.toc.actions * 56 + 10 + "px",
                            },
                          },
                          _vm._l(_vm.formActionsFiltered, function (action) {
                            return _c("asyent-button", {
                              key: action["name"],
                              attrs: {
                                "button-icon": action["icon"],
                                "button-tooltip": action["tooltip"],
                                "requires-confirmation": action["action"],
                                "as-list-item": "",
                                "dense-list": "",
                                "as-avatar": "",
                                text: "",
                                small: "",
                              },
                              on: {
                                confirmed: function ($event) {
                                  return _vm.formActionMethod(action)
                                },
                              },
                            })
                          }),
                          1
                        ),
                      ]
                    : _vm._e(),
                ],
                2
              )
            : _vm._e(),
        ],
        1
      )
    : _c("div", [_vm._t("default")], 2)
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }