var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._t("default", function () {
        return [
          !_vm.noLabel
            ? _c("div", [_vm._v(_vm._s(_vm.label)), _vm._t("labelButton")], 2)
            : _vm._e(),
          _vm._t(
            "content",
            function () {
              return [
                !_vm.link && !_vm.showActivator
                  ? _c("div", {
                      staticClass: "font-weight-bold pb-2",
                      class: { "text-truncate": _vm.truncate },
                      domProps: { innerHTML: _vm._s(_vm.printValue) },
                    })
                  : _vm._e(),
                _vm.link || _vm.showActivator
                  ? _c(
                      "asyent-link",
                      {
                        attrs: {
                          link: _vm.link,
                          "show-activator": _vm.showActivator,
                        },
                      },
                      [_vm._v(_vm._s(_vm.printValue))]
                    )
                  : _vm._e(),
              ]
            },
            { printValue: _vm.printValue, value: _vm.value, link: _vm.link }
          ),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }