var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: {
        fullscreen: _vm.$vuetify.breakpoint.smAndDown || _vm.maximized,
        persistent: "",
        scrollable: "",
      },
      scopedSlots: _vm._u(
        [
          {
            key: "activator",
            fn: function (ref) {
              var on = ref.on
              return [
                _c(
                  "v-card",
                  _vm._g(
                    _vm._b(
                      {
                        staticClass: "fill-height pb-0 mb-0",
                        class: Object.assign(
                          {},
                          {
                            "py-10 px-4": _vm.hasPadding,
                            "card-border": _vm.hasBorder,
                            "card-border-primary":
                              _vm.hasBorder && _vm.borderColor === 1,
                            "card-border-secondary":
                              _vm.hasBorder && _vm.borderColor === 2,
                            "card-border-accent":
                              _vm.hasBorder && _vm.borderColor === 3,
                          },
                          _vm.cardClass
                        ),
                        style: Object.assign({}, _vm.cardStyle, {
                          opacity: _vm.opacity,
                        }),
                        attrs: {
                          elevation: _vm.elevation,
                          rounded: "",
                          outlined: "",
                        },
                      },
                      "v-card",
                      _vm.$attrs,
                      false
                    ),
                    _vm.$listeners
                  ),
                  [
                    _c("asyent-toc-heading", {
                      attrs: {
                        id: _vm.tocId,
                        caption: _vm.title,
                        level: _vm.tocLevel,
                      },
                    }),
                    !_vm.noMaximize || !_vm.noTitle
                      ? _c(
                          "v-toolbar",
                          {
                            attrs: {
                              color: _vm.toolbarColor1,
                              dark: _vm.toolbarDark1,
                              dense: "",
                              flat: "",
                            },
                            on: {
                              dblclick: function ($event) {
                                _vm.expandable
                                  ? (_vm.expanded = !_vm.expanded)
                                  : true
                              },
                            },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "mr-5" },
                              [
                                _vm._t("titleIcon", function () {
                                  return [
                                    _vm.titleIcon && _vm.titleIcon.length > 0
                                      ? _c(
                                          "v-icon",
                                          { attrs: { color: "primary" } },
                                          [_vm._v(_vm._s(_vm.titleIcon))]
                                        )
                                      : _vm._e(),
                                  ]
                                }),
                              ],
                              2
                            ),
                            !_vm.noTitle && _vm.title && _vm.title.length > 0
                              ? _c(
                                  "v-toolbar-title",
                                  [
                                    _vm._t(
                                      "title",
                                      function () {
                                        return [
                                          _c(
                                            "span",
                                            {
                                              class:
                                                _vm.tocLevel === 1 ||
                                                _vm.tocTitle
                                                  ? "title"
                                                  : "subtitle-1 text-decoration-underline font-weight-medium",
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.title) +
                                                  " " +
                                                  _vm._s(
                                                    _vm.help
                                                      ? " - " +
                                                          _vm.ae$lng(
                                                            "card.help"
                                                          )
                                                      : ""
                                                  )
                                              ),
                                            ]
                                          ),
                                        ]
                                      },
                                      { title: _vm.title }
                                    ),
                                  ],
                                  2
                                )
                              : _vm._e(),
                            _c("v-spacer"),
                            _vm._t("toolbar-actions"),
                            _vm.showHelp
                              ? _c(
                                  "v-tooltip",
                                  {
                                    attrs: { bottom: "" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function (ref) {
                                            var ton = ref.on
                                            return [
                                              _c(
                                                "v-btn",
                                                _vm._g(
                                                  {
                                                    attrs: {
                                                      "aria-label":
                                                        _vm.ae$lng("card.help"),
                                                      icon: "",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        _vm.help = !_vm.help
                                                      },
                                                    },
                                                  },
                                                  Object.assign({}, ton)
                                                ),
                                                [
                                                  _c(
                                                    "v-icon",
                                                    { attrs: { small: "" } },
                                                    [_vm._v("mdi-help")]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(_vm._s(_vm.ae$lng("card.help"))),
                                    ]),
                                  ]
                                )
                              : _vm._e(),
                            _vm.expandable
                              ? _c(
                                  "v-tooltip",
                                  {
                                    attrs: { bottom: "" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function (ref) {
                                            var ton = ref.on
                                            return [
                                              _vm.expandable
                                                ? _c(
                                                    "v-btn",
                                                    _vm._g(
                                                      {
                                                        attrs: {
                                                          "aria-label":
                                                            _vm.expanded
                                                              ? _vm.ae$lng(
                                                                  "card.collapse"
                                                                )
                                                              : _vm.ae$lng(
                                                                  "card.expand"
                                                                ),
                                                          icon: "",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            _vm.expanded =
                                                              !_vm.expanded
                                                          },
                                                        },
                                                      },
                                                      ton
                                                    ),
                                                    [
                                                      _vm.expanded
                                                        ? _c("v-icon", [
                                                            _vm._v(
                                                              "mdi-chevron-up"
                                                            ),
                                                          ])
                                                        : _vm._e(),
                                                      !_vm.expanded
                                                        ? _c("v-icon", [
                                                            _vm._v(
                                                              "mdi-chevron-down"
                                                            ),
                                                          ])
                                                        : _vm._e(),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  },
                                  [
                                    _vm.expanded
                                      ? _c("span", [
                                          _vm._v(
                                            _vm._s(_vm.ae$lng("card.collapse"))
                                          ),
                                        ])
                                      : _vm._e(),
                                    !_vm.expanded
                                      ? _c("span", [
                                          _vm._v(
                                            _vm._s(_vm.ae$lng("card.expand"))
                                          ),
                                        ])
                                      : _vm._e(),
                                  ]
                                )
                              : _vm._e(),
                            !_vm.noMaximize
                              ? _c(
                                  "v-tooltip",
                                  {
                                    attrs: { bottom: "" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function (ref) {
                                            var ton = ref.on
                                            return [
                                              _c(
                                                "v-btn",
                                                _vm._g(
                                                  {
                                                    attrs: {
                                                      "aria-label":
                                                        _vm.ae$lng(
                                                          "card.maximize"
                                                        ),
                                                      icon: "",
                                                    },
                                                  },
                                                  Object.assign({}, on, ton)
                                                ),
                                                [
                                                  _c(
                                                    "v-icon",
                                                    { attrs: { small: "" } },
                                                    [_vm._v("mdi-arrow-expand")]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(_vm.ae$lng("card.maximize"))
                                      ),
                                    ]),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          2
                        )
                      : _vm._e(),
                    !_vm.help
                      ? [
                          _vm.expanded
                            ? _c(
                                "v-card-text",
                                {
                                  staticClass: "pb-0 mb-0 pt-0 mt-0",
                                  class: { "pl-0 pr-0": _vm.extended },
                                },
                                [_vm._t("default")],
                                2
                              )
                            : _vm._e(),
                          _vm._t("actions"),
                        ]
                      : [
                          _vm._t("help", function () {
                            return [
                              _c(
                                "v-card-text",
                                {
                                  staticClass:
                                    "overflow-auto pb-0 mb-0 pt-0 mt-0 text-justify",
                                  class: { "pl-0 pr-0": _vm.extended },
                                },
                                [
                                  _c("span", {
                                    domProps: {
                                      innerHTML: _vm._s(_vm.helpText),
                                    },
                                  }),
                                ]
                              ),
                            ]
                          }),
                        ],
                  ],
                  2
                ),
              ]
            },
          },
        ],
        null,
        true
      ),
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      !_vm.noMaximize
        ? _c(
            "v-card",
            _vm._g(
              _vm._b(
                {
                  staticClass: "fill-height pb-0 mb-0",
                  class: { "py-10 px-4": _vm.hasPadding },
                  staticStyle: { opacity: "100%" },
                  attrs: { tile: "" },
                },
                "v-card",
                _vm.$attrs,
                false
              ),
              _vm.$listeners
            ),
            [
              !_vm.noMaximize || !_vm.noTitle
                ? _c(
                    "v-toolbar",
                    {
                      attrs: {
                        color: _vm.toolbarColor2,
                        dark: _vm.toolbarDark2,
                        flat: "",
                        height: "56",
                        "max-height": "56",
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "mr-5" },
                        [
                          _vm._t("titleIcon", function () {
                            return [
                              _vm.titleIcon && _vm.titleIcon.length > 0
                                ? _c(
                                    "v-icon",
                                    { attrs: { color: "primary" } },
                                    [_vm._v(_vm._s(_vm.titleIcon))]
                                  )
                                : _vm._e(),
                            ]
                          }),
                        ],
                        2
                      ),
                      !_vm.noTitleOnExpanded &&
                      _vm.title &&
                      _vm.title.length > 0
                        ? _c(
                            "v-toolbar-title",
                            [
                              _vm._t(
                                "title",
                                function () {
                                  return [_vm._v(_vm._s(_vm.title))]
                                },
                                { title: _vm.title }
                              ),
                            ],
                            2
                          )
                        : _vm._e(),
                      _c("v-spacer"),
                      _vm._t("toolbar-actions"),
                      _vm.showHelp
                        ? _c(
                            "v-tooltip",
                            {
                              attrs: { bottom: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function (ref) {
                                      var ton = ref.on
                                      return [
                                        _c(
                                          "v-btn",
                                          _vm._g(
                                            {
                                              attrs: {
                                                "aria-label":
                                                  _vm.ae$lng("card.help"),
                                                icon: "",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  _vm.help = !_vm.help
                                                },
                                              },
                                            },
                                            Object.assign({}, ton)
                                          ),
                                          [
                                            _c(
                                              "v-icon",
                                              { attrs: { small: "" } },
                                              [_vm._v("mdi-help")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                1467343083
                              ),
                            },
                            [
                              _c("span", [
                                _vm._v(_vm._s(_vm.ae$lng("card.help"))),
                              ]),
                            ]
                          )
                        : _vm._e(),
                      !_vm.noMaximize
                        ? _c(
                            "v-tooltip",
                            {
                              attrs: { bottom: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function (ref) {
                                      var ton = ref.on
                                      return [
                                        !_vm.noMaximize
                                          ? _c(
                                              "v-btn",
                                              _vm._g(
                                                {
                                                  attrs: {
                                                    "aria-label":
                                                      _vm.ae$lng(
                                                        "card.collapse"
                                                      ),
                                                    icon: "",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      _vm.dialog = false
                                                    },
                                                  },
                                                },
                                                ton
                                              ),
                                              [
                                                _c("v-icon", [
                                                  _vm._v("mdi-arrow-collapse"),
                                                ]),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                1280937801
                              ),
                            },
                            [
                              _c("span", [
                                _vm._v(_vm._s(_vm.ae$lng("card.collapse"))),
                              ]),
                            ]
                          )
                        : _vm._e(),
                      !_vm.noMaximize
                        ? _c(
                            "v-tooltip",
                            {
                              attrs: { bottom: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function (ref) {
                                      var ton = ref.on
                                      return [
                                        !_vm.noMaximize &&
                                        !_vm.$vuetify.breakpoint.smAndDown
                                          ? _c(
                                              "v-btn",
                                              _vm._g(
                                                {
                                                  attrs: {
                                                    "aria-label": _vm.maximized
                                                      ? _vm.ae$lng(
                                                          "card.minimize"
                                                        )
                                                      : _vm.ae$lng(
                                                          "card.maximize"
                                                        ),
                                                    icon: "",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      if (
                                                        $event.ctrlKey ||
                                                        $event.shiftKey ||
                                                        $event.altKey ||
                                                        $event.metaKey
                                                      ) {
                                                        return null
                                                      }
                                                      _vm.maximized =
                                                        !_vm.maximized
                                                    },
                                                  },
                                                },
                                                ton
                                              ),
                                              [
                                                _vm.maximized
                                                  ? _c("v-icon", [
                                                      _vm._v(
                                                        "mdi-window-minimize"
                                                      ),
                                                    ])
                                                  : _c("v-icon", [
                                                      _vm._v(
                                                        "mdi-window-maximize"
                                                      ),
                                                    ]),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                2896390504
                              ),
                            },
                            [
                              !_vm.maximized
                                ? _c("span", [
                                    _vm._v(_vm._s(_vm.ae$lng("card.maximize"))),
                                  ])
                                : _vm._e(),
                              _vm.maximized
                                ? _c("span", [
                                    _vm._v(_vm._s(_vm.ae$lng("card.minimize"))),
                                  ])
                                : _vm._e(),
                            ]
                          )
                        : _vm._e(),
                    ],
                    2
                  )
                : _vm._e(),
              !_vm.help
                ? [
                    _c(
                      "v-card-text",
                      {
                        staticClass: "pb-0 mb-0 pt-0 mt-0",
                        class: { "pl-0 pr-0": _vm.extended },
                      },
                      [_vm._t("default")],
                      2
                    ),
                    _vm._t("actions"),
                  ]
                : [
                    _vm._t("help", function () {
                      return [
                        _c(
                          "v-card-text",
                          {
                            staticClass:
                              "overflow-auto pb-0 mb-0 pt-0 mt-0 text-justify",
                            class: { "pl-0 pr-0": _vm.extended },
                          },
                          [
                            _c("span", {
                              domProps: { innerHTML: _vm._s(_vm.helpText) },
                            }),
                          ]
                        ),
                      ]
                    }),
                  ],
            ],
            2
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }