var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.showActivator
    ? _c(
        "router-link",
        { attrs: { to: _vm.link, tag: "a", target: "_blank" } },
        [
          _c(
            "span",
            _vm._g(
              _vm._b(
                { staticClass: "link font-weight-bold text--secondary" },
                "span",
                _vm.$attrs,
                false
              ),
              _vm.$listeners
            ),
            [_vm._t("default")],
            2
          ),
          _vm._v(" "),
          _c(
            "v-icon",
            {
              staticStyle: { "text-decoration": "unset" },
              attrs: { size: "20", color: "red" },
            },
            [_vm._v("mdi-open-in-new")]
          ),
        ],
        1
      )
    : _c(
        "span",
        _vm._g(
          _vm._b(
            { staticClass: "link font-weight-bold text--secondary" },
            "span",
            _vm.$attrs,
            false
          ),
          _vm.$listeners
        ),
        [
          _vm._t("default"),
          _vm._v(" "),
          _c(
            "v-icon",
            {
              staticStyle: { "text-decoration": "unset" },
              attrs: { size: "20" },
            },
            [_vm._v("mdi-open-in-new")]
          ),
        ],
        2
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }