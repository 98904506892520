var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.$vuetify.breakpoint.lgAndUp
    ? _c(
        "v-navigation-drawer",
        {
          class: { "elevation-3": _vm.appConfig.navigation.elevation },
          style: { opacity: _vm.appConfig.navigation.opacity },
          attrs: {
            clipped: _vm.appConfig.navigation.clipped,
            floating: _vm.appConfig.navigation.floating,
            width: _vm.drawerWidth,
            app: "",
            permanent: _vm.appConfig.navigation.permanent,
            color:
              _vm.appConfig.navigation.transparent &&
              _vm.appConfig.navigation.fixed
                ? "drawer"
                : "drawer",
            fixed: _vm.appConfig.navigation.fixed,
            "expand-on-hover": _vm.drawer,
            temporary:
              !_vm.appConfig.navigation.fixed && !_vm.appConfig.navigation.mini,
          },
          model: {
            value: _vm.drawer,
            callback: function ($$v) {
              _vm.drawer = $$v
            },
            expression: "drawer",
          },
        },
        [
          _c("asyent-drawer-menu", {
            attrs: { "drawer-menu": _vm.drawerMenu, nav: "" },
          }),
        ],
        1
      )
    : _c(
        "v-navigation-drawer",
        {
          class: { "elevation-3": _vm.appConfig.navigation.elevation },
          style: { opacity: _vm.appConfig.navigation.opacity },
          attrs: {
            clipped: false,
            floating: _vm.appConfig.navigation.floating,
            width: _vm.drawerWidth,
            app: "",
            permanent: false,
            color:
              _vm.appConfig.navigation.transparent &&
              _vm.appConfig.navigation.fixed
                ? "drawer"
                : "drawer",
            fixed: false,
            "mini-variant": false,
            "expand-on-hover": false,
            temporary:
              !_vm.appConfig.navigation.fixed && !_vm.appConfig.navigation.mini,
          },
          model: {
            value: _vm.drawer,
            callback: function ($$v) {
              _vm.drawer = $$v
            },
            expression: "drawer",
          },
        },
        [_c("asyent-drawer-menu")],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }