var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-list",
    _vm._b(
      { attrs: { width: _vm.drawerWidth, nav: "", expand: "", dense: "" } },
      "v-list",
      _vm.$attrs,
      false
    ),
    [
      _c(
        "v-list-item",
        { staticClass: "mx-n4 first-level" },
        [
          _c(
            "v-list-item-avatar",
            { attrs: { color: "drawerAvatar" } },
            [
              _c(
                "v-icon",
                { attrs: { size: _vm.appConfig.navigation.iconSize } },
                [_vm._v(" mdi-magnify ")]
              ),
            ],
            1
          ),
          _c(
            "v-list-item-content",
            [
              _c(
                "v-list-item-title",
                { staticClass: "text-caption" },
                [
                  _vm.appConfig.navigation.searchable
                    ? _c("v-text-field", {
                        attrs: {
                          "background-color": "searchColor",
                          "aria-label": _vm.ae$lng(
                            "drawerMenu.searchForEDocuments"
                          ),
                          autofocus: _vm.$vuetify.breakpoint.mdAndUp,
                          flat: "",
                          "hide-details": "",
                          placeholder: _vm.ae$lng("drawerMenu.searchExample"),
                          dense: "",
                          outlined: "",
                        },
                        model: {
                          value: _vm.drawerFilter,
                          callback: function ($$v) {
                            _vm.drawerFilter = $$v
                          },
                          expression: "drawerFilter",
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-treeview",
        { attrs: { hoverable: "" } },
        [
          _vm._l(
            _vm.menuLibrary.filter(function (x) {
              return !x.children
            }),
            function (item) {
              return _c(
                "v-list-item",
                _vm._b(
                  {
                    key: item.target.name,
                    staticClass: "first-level",
                    attrs: { link: "" },
                  },
                  "v-list-item",
                  item.target,
                  false
                ),
                [
                  _c(
                    "v-list-item-icon",
                    [_c("v-icon", [_vm._v(" " + _vm._s(item.icon) + " ")])],
                    1
                  ),
                  _c(
                    "v-list-item-content",
                    [
                      _c("v-list-item-title", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.ae$prefixLng(
                                _vm.mI18n(item) + "._library",
                                item["shortName"]
                                  ? item["shortName"]
                                  : item["name"]
                              )
                            ) +
                            " "
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              )
            }
          ),
          _vm._l(
            _vm.menuLibrary.filter(function (x) {
              return x.children
            }),
            function (item, i) {
              return _c(
                "v-list-group",
                {
                  key: i,
                  staticClass: "first-level",
                  attrs: { item: item, "no-action": "" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "prependIcon",
                        fn: function () {
                          return [
                            _c("v-icon", [
                              _vm._v(" " + _vm._s(item.icon) + " "),
                            ]),
                          ]
                        },
                        proxy: true,
                      },
                      {
                        key: "activator",
                        fn: function () {
                          return [
                            _c("v-list-item-title", [
                              _vm._v(
                                _vm._s(
                                  _vm.ae$prefixLng(
                                    _vm.mI18n(item) + "._library",
                                    item["shortName"]
                                      ? item["shortName"]
                                      : item["name"]
                                  )
                                )
                              ),
                            ]),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    true
                  ),
                },
                [
                  _vm._l(item.children, function (subitem, j) {
                    return [
                      subitem.children
                        ? _c(
                            "v-list-group",
                            {
                              key: i + "" + j,
                              attrs: {
                                color: _vm.$vuetify.theme.dark ? "" : "primary",
                                "no-action": "",
                                "sub-group": "",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function () {
                                      return [
                                        subitem.type === "route"
                                          ? _c(
                                              "v-list-item",
                                              { attrs: { link: "", to: "#" } },
                                              [
                                                _c("v-list-item-title", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.ae$prefixLng(
                                                          _vm.mI18n(subitem) +
                                                            "._library",
                                                          subitem["shortName"]
                                                            ? subitem[
                                                                "shortName"
                                                              ]
                                                            : subitem["name"]
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ]),
                                              ],
                                              1
                                            )
                                          : _c(
                                              "v-list-item-content",
                                              [
                                                _c("v-list-item-title", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.ae$prefixLng(
                                                          _vm.mI18n(subitem) +
                                                            "._library",
                                                          subitem["shortName"]
                                                            ? subitem[
                                                                "shortName"
                                                              ]
                                                            : subitem["name"]
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ]),
                                              ],
                                              1
                                            ),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                true
                              ),
                            },
                            [
                              subitem.children
                                ? _vm._l(
                                    subitem.children,
                                    function (subsubitem, k) {
                                      return _c(
                                        "v-list-item",
                                        _vm._b(
                                          {
                                            key: j + "" + k,
                                            attrs: { link: "" },
                                          },
                                          "v-list-item",
                                          subsubitem.target,
                                          false
                                        ),
                                        [
                                          _c("v-list-item-title", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.ae$prefixLng(
                                                    _vm.mI18n(subsubitem) +
                                                      "._library",
                                                    subsubitem["shortName"]
                                                      ? subsubitem["shortName"]
                                                      : subsubitem["name"]
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]),
                                        ],
                                        1
                                      )
                                    }
                                  )
                                : _vm._e(),
                            ],
                            2
                          )
                        : _c(
                            "v-list-item",
                            _vm._b(
                              { key: subitem.target.name, attrs: { link: "" } },
                              "v-list-item",
                              subitem.target,
                              false
                            ),
                            [
                              _c("v-list-item-title", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.ae$prefixLng(
                                        _vm.mI18n(subitem) + "._library",
                                        subitem["shortName"]
                                          ? subitem["shortName"]
                                          : subitem["name"]
                                      )
                                    ) +
                                    " "
                                ),
                              ]),
                            ],
                            1
                          ),
                    ]
                  }),
                ],
                2
              )
            }
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }