import { render, staticRenderFns } from "./_asyent-form-file.vue?vue&type=template&id=f570674a&scoped=true&"
import script from "./_asyent-form-file.vue?vue&type=script&lang=js&"
export * from "./_asyent-form-file.vue?vue&type=script&lang=js&"
import style0 from "./_asyent-form-file.vue?vue&type=style&index=0&id=f570674a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f570674a",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VFileInput } from 'vuetify/lib/components/VFileInput';
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VBtn,VFileInput,VIcon})


/* hot reload */
if (module.hot) {
  var api = require("D:\\asyhub-workspace\\asyhub-platform-ui\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('f570674a')) {
      api.createRecord('f570674a', component.options)
    } else {
      api.reload('f570674a', component.options)
    }
    module.hot.accept("./_asyent-form-file.vue?vue&type=template&id=f570674a&scoped=true&", function () {
      api.rerender('f570674a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/_asyent-form-file.vue"
export default component.exports