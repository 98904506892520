var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-app-bar",
        {
          attrs: {
            "clipped-left":
              _vm.appConfig.navigation.clipped &&
              _vm.$vuetify.breakpoint.mdAndUp,
            "clipped-right":
              _vm.appConfig.toc.clipped && _vm.$vuetify.breakpoint.mdAndUp,
            app: "",
            color: "primary",
            dark: "",
          },
          on: {
            dblclick: [
              function ($event) {
                if (
                  $event.ctrlKey ||
                  $event.shiftKey ||
                  $event.altKey ||
                  $event.metaKey
                ) {
                  return null
                }
                return _vm.onDblClick(1)
              },
              function ($event) {
                if (!$event.shiftKey) {
                  return null
                }
                return _vm.onDblClick(2)
              },
            ],
          },
        },
        [
          !_vm.standalone &&
          _vm.$store.getters["menu/drawerMenu"].length > 0 &&
          (!_vm.appConfig.navigation.permanent ||
            _vm.$vuetify.breakpoint.mdAndDown)
            ? _c("v-app-bar-nav-icon", {
                attrs: { "aria-label": _vm.ae$lng("toolbar.applicationMenu") },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.setDrawer.apply(null, arguments)
                  },
                },
              })
            : _vm._e(),
          _c(
            "router-link",
            {
              style:
                "height: " +
                (_vm.$vuetify.breakpoint.mdAndUp
                  ? _vm.logo.height.large
                  : _vm.logo.height.small) +
                "px !important",
              attrs: { to: "/", exact: "" },
            },
            [
              _vm.logo.visible
                ? _c("img", {
                    attrs: {
                      alt: _vm.ae$lng("toolbar.applicationIcon"),
                      height: _vm.$vuetify.breakpoint.mdAndUp
                        ? _vm.logo.height.large
                        : _vm.logo.height.small,
                      width: _vm.$vuetify.breakpoint.mdAndUp
                        ? _vm.logo.width.large
                        : _vm.logo.width.small,
                      src: _vm.logo.path,
                    },
                  })
                : _vm._e(),
            ]
          ),
          _vm.$vuetify.breakpoint.mdAndUp &&
          _vm.ae$lng("toolbar.title").length > 0
            ? _c(
                "v-toolbar-title",
                { staticClass: "ml-0 pl-3 text-truncate text-no-wrap" },
                [_vm._v(" " + _vm._s(_vm.ae$lng("toolbar.title")) + " ")]
              )
            : _vm._e(),
          _c("v-spacer"),
          _vm.appConfig.toolbar.tools.history && _vm.$vuetify.breakpoint.mdAndUp
            ? _c(
                "v-btn-toggle",
                { attrs: { dense: "", "background-color": "transparent" } },
                [
                  _c("asyent-button", {
                    attrs: {
                      "button-icon": "mdi-arrow-left",
                      "button-tooltip": _vm.ae$lng("toolbar.back"),
                      icon: "",
                    },
                    on: {
                      confirmed: function ($event) {
                        return _vm.$router.history.go(-1)
                      },
                    },
                  }),
                  _c("asyent-button", {
                    attrs: {
                      "button-icon": "mdi-home",
                      "button-tooltip": _vm.ae$lng("toolbar.homepage"),
                      icon: "",
                    },
                    on: {
                      confirmed: function ($event) {
                        _vm.$router.push("/").catch(function () {})
                      },
                    },
                  }),
                  _c("asyent-button", {
                    attrs: {
                      "button-icon": "mdi-arrow-right",
                      "button-tooltip": _vm.ae$lng("toolbar.forward"),
                      icon: "",
                    },
                    on: {
                      confirmed: function ($event) {
                        return _vm.$router.go(1)
                      },
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.$vuetify.breakpoint.mdAndUp && _vm.appConfig.toolbar.tools.screen
            ? _c("asyent-button", {
                attrs: {
                  "button-icon": "mdi-fullscreen",
                  "button-tooltip": _vm.ae$lng("toolbar.fullscreen"),
                  icon: "",
                },
                on: { confirmed: _vm.toggleFullScreen },
              })
            : _vm._e(),
          _vm.appConfig.toolbar.tools.theme
            ? _c("asyent-button", {
                attrs: {
                  "button-icon": _vm.isDark
                    ? "mdi-invert-colors"
                    : "mdi-invert-colors-off",
                  "button-tooltip": _vm.isDark
                    ? _vm.ae$lng("toolbar.turnLightMode")
                    : _vm.ae$lng("toolbar.turnDarkMode"),
                  icon: "",
                  dark: "",
                },
                on: { confirmed: _vm.switchTheme },
              })
            : _vm._e(),
          _vm.appConfig.toolbar.tools.language &&
          Object.keys(_vm.appConfig.i18n.supportedLanguages).length > 1
            ? _c(
                "v-menu",
                {
                  attrs: {
                    bottom: "",
                    left: "",
                    "offset-y": "",
                    "aria-label": _vm.ae$lng("toolbar.languageMenu"),
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function (ref) {
                          var on = ref.on
                          var attrs = ref.attrs
                          return [
                            _c(
                              "v-btn",
                              _vm._g(
                                _vm._b(
                                  { attrs: { icon: "", fab: "" } },
                                  "v-btn",
                                  attrs,
                                  false
                                ),
                                on
                              ),
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.$root.$i18n.locale) + " "
                                ),
                              ]
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    4101156300
                  ),
                },
                [
                  _c(
                    "v-list",
                    _vm._l(
                      _vm.appConfig.i18n.supportedLanguages,
                      function (item, key) {
                        return _c(
                          "v-list-item",
                          {
                            key: key,
                            on: {
                              click: function ($event) {
                                return _vm.changeLocale(key)
                              },
                            },
                          },
                          [
                            _c("v-list-item-title", [
                              _vm._v(_vm._s(item) + " (" + _vm._s(key) + ")"),
                            ]),
                          ],
                          1
                        )
                      }
                    ),
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.$store.getters["toolbar/toolbarMenu"].length > 0
            ? _c("span", { staticClass: "pl-6" }, [_vm._v(" ")])
            : _vm._e(),
          _c(
            "v-menu",
            {
              attrs: {
                bottom: "",
                left: "",
                "offset-y": "",
                "aria-label": _vm.ae$lng("toolbar.toolbarMenu"),
              },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function (ref) {
                    var on = ref.on
                    return [
                      _vm.$store.getters["toolbar/toolbarMenu"].length > 0
                        ? _c(
                            "v-btn",
                            _vm._g(
                              {
                                attrs: {
                                  icon: "",
                                  ripple: "",
                                  "aria-label": _vm.ae$lng(
                                    "toolbar.openToolbarMenu"
                                  ),
                                  light: !_vm.$vuetify.theme.dark,
                                },
                              },
                              on
                            ),
                            [
                              _c("v-avatar", {
                                staticStyle: {
                                  "justify-content": "center !important",
                                },
                                attrs: {
                                  color: _vm.$vuetify.theme.dark
                                    ? _vm.appConfig.toolbar.avatar.color.tool[1]
                                    : _vm.appConfig.toolbar.avatar.color
                                        .tool[0],
                                  size: _vm.$vuetify.breakpoint.smAndDown
                                    ? 40
                                    : _vm.appConfig.toolbar.avatar.size[0],
                                },
                                domProps: {
                                  textContent: _vm._s(
                                    _vm.sso.authenticated()
                                      ? _vm.sso.initials()
                                      : "?"
                                  ),
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  },
                },
              ]),
            },
            [
              _c(
                "v-list",
                { attrs: { "max-width": "286" } },
                [
                  _vm.sso.authenticated() &&
                  _vm.sso.name() &&
                  _vm.sso.username()
                    ? _c(
                        "v-list-item",
                        { attrs: { dense: "", ripple: false, inactive: "" } },
                        [
                          _c("v-list-item-avatar", {
                            staticStyle: {
                              "justify-content": "center !important",
                            },
                            attrs: {
                              color: _vm.$vuetify.theme.dark
                                ? _vm.appConfig.toolbar.avatar.color.menu[1]
                                : _vm.appConfig.toolbar.avatar.color.menu[0],
                              size: _vm.$vuetify.breakpoint.smAndDown
                                ? 40
                                : _vm.appConfig.toolbar.avatar.size[0],
                            },
                            domProps: {
                              textContent: _vm._s(_vm.sso.initials()),
                            },
                          }),
                          _c(
                            "v-list-item-content",
                            [
                              _c(
                                "v-list-item-title",
                                { staticClass: "text-no-wrap text-truncate" },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.sso.nameWithInitials()) +
                                      " "
                                  ),
                                  _c("span", {
                                    staticClass:
                                      "text-no-wrap text-truncate caption",
                                    domProps: {
                                      textContent: _vm._s(_vm.sso.username()),
                                    },
                                  }),
                                ]
                              ),
                              _c("v-list-item-subtitle", {
                                staticClass: "text-no-wrap text-truncate",
                                domProps: {
                                  textContent: _vm._s(_vm.sso.email()),
                                },
                              }),
                              _c("v-list-item-subtitle", {
                                staticClass: "text-no-wrap text-truncate",
                                domProps: {
                                  textContent: _vm._s(_vm.sso.phone()),
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.sso.authenticated() &&
                  _vm.sso.name() &&
                  _vm.sso.username()
                    ? _c("v-divider")
                    : _vm._e(),
                  _vm._l(
                    _vm.$store.getters["toolbar/toolbarMenu"],
                    function (item, i) {
                      return _c(
                        "v-list-item",
                        _vm._b(
                          { key: i, attrs: { dense: "", exact: "" } },
                          "v-list-item",
                          item.target,
                          false
                        ),
                        [
                          _c(
                            "v-list-item-action",
                            [_c("v-icon", [_vm._v(_vm._s(item.icon))])],
                            1
                          ),
                          _c("v-list-item-title", [
                            _vm._v(
                              _vm._s(
                                _vm.ae$prefixLng(
                                  _vm.mI18n(item) + "._library",
                                  item.name
                                )
                              ) + " "
                            ),
                          ]),
                        ],
                        1
                      )
                    }
                  ),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }