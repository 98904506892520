import { render, staticRenderFns } from "./_asyent-dashboard-panel.vue?vue&type=template&id=8c0820fe&scoped=true&"
import script from "./_asyent-dashboard-panel.vue?vue&type=script&lang=js&"
export * from "./_asyent-dashboard-panel.vue?vue&type=script&lang=js&"
import style0 from "./_asyent-dashboard-panel.vue?vue&type=style&index=0&id=8c0820fe&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8c0820fe",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VBtn,VCard,VCardText,VCardTitle,VIcon,VListItem,VListItemAction,VListItemAvatar,VListItemContent,VListItemSubtitle,VListItemTitle,VTooltip})


/* hot reload */
if (module.hot) {
  var api = require("D:\\asyhub-workspace\\asyhub-platform-ui\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('8c0820fe')) {
      api.createRecord('8c0820fe', component.options)
    } else {
      api.reload('8c0820fe', component.options)
    }
    module.hot.accept("./_asyent-dashboard-panel.vue?vue&type=template&id=8c0820fe&scoped=true&", function () {
      api.rerender('8c0820fe', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/_asyent-dashboard-panel.vue"
export default component.exports