var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    {
      staticClass: "pa-6",
      staticStyle: {
        "z-index": "1",
        overflow: "auto",
        position: "absolute",
        bottom: "0",
        top: "0",
        left: "0",
        right: "0",
        width: "100%",
      },
      attrs: { id: "scroll-target", fluid: "" },
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }