var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.value && (_vm.viewMode || _vm.value["uid"])
        ? _c("asyent-form-display", {
            staticClass: "pb-5",
            attrs: {
              value: [
                _vm.value["name"],
                _vm.ae$formatNumber(_vm.value["size"]) + " bytes",
              ],
              label: _vm.label,
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "content",
                  fn: function () {
                    return [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            text: "",
                            left: "",
                            small: "",
                            loading: _vm.loading,
                          },
                          on: { click: _vm.download },
                        },
                        [
                          _c("v-icon", [_vm._v("mdi-download")]),
                          _c(
                            "span",
                            { staticClass: "text-truncate text-body-2" },
                            [
                              _vm._v(
                                "Download `" +
                                  _vm._s(_vm.value["name"]) +
                                  " (" +
                                  _vm._s(
                                    _vm.ae$formatNumber(_vm.value["size"]) +
                                      " bytes"
                                  ) +
                                  ")`"
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              547296269
            ),
          })
        : _vm._e(),
      !_vm.noLabel && !_vm.viewMode && !_vm.value["uid"]
        ? _c("div", [
            _c(
              "span",
              {
                class: { "field-mandatory": _vm.mandatory },
                staticStyle: { "font-size": "0.85rem" },
              },
              [_vm._v(_vm._s(_vm.label))]
            ),
          ])
        : _vm._e(),
      !_vm.viewMode && !_vm.value["uid"]
        ? _c("ValidationProvider", {
            attrs: {
              name: _vm.label,
              rules: _vm.formFieldRules,
              vid: _vm.name,
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function (ref) {
                    var errors = ref.errors
                    return [
                      _c(
                        "v-file-input",
                        _vm._g(
                          _vm._b(
                            {
                              staticClass: "mt-2",
                              attrs: {
                                accept: _vm.accept,
                                placeholder: "...",
                                "aria-label": _vm.label,
                                disabled:
                                  _vm.formFieldDisabled ||
                                  _vm.viewMode ||
                                  _vm.loading,
                                "error-messages": _vm.formFieldErrors(errors),
                                multiple: false,
                                "show-size": "",
                                success: _vm.uploaded,
                                clearable: "",
                                loading: _vm.loading,
                                label: "",
                                dense: _vm.appConfig.forms.dense,
                                flat: _vm.appConfig.forms.flat,
                                solo: _vm.appConfig.forms.solo,
                                "solo-inverted":
                                  _vm.appConfig.forms.soloInverted,
                                filled: _vm.appConfig.forms.filled,
                                outlined: _vm.appConfig.forms.outlined,
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.upload($event)
                                },
                              },
                            },
                            "v-file-input",
                            _vm.$attrs,
                            false
                          ),
                          _vm.$listeners
                        )
                      ),
                    ]
                  },
                },
              ],
              null,
              false,
              937329405
            ),
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }