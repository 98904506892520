var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { style: "background-color: " + _vm.divColor + " !important" },
    [
      _c(
        "v-list-item",
        { staticClass: "mx-n4" },
        [
          _c(
            "v-list-item-avatar",
            { attrs: { color: "drawerAvatar", size: "40" } },
            [
              _c(
                "v-icon",
                { attrs: { size: _vm.appConfig.navigation.iconSize } },
                [_vm._v(" mdi-magnify ")]
              ),
            ],
            1
          ),
          _c(
            "v-list-item-content",
            [
              _c(
                "v-list-item-title",
                { staticClass: "text-caption" },
                [
                  _vm.appConfig.navigation.searchable
                    ? _c("v-text-field", {
                        attrs: {
                          "background-color": "searchColor",
                          "aria-label": _vm.ae$lng(
                            "drawerMenu.searchForEDocuments"
                          ),
                          autofocus: _vm.$vuetify.breakpoint.mdAndUp,
                          flat: "",
                          "hide-details": "",
                          placeholder: _vm.ae$lng("drawerMenu.searchExample"),
                          dense: "",
                          outlined: "",
                        },
                        model: {
                          value: _vm.drawerFilter,
                          callback: function ($$v) {
                            _vm.drawerFilter = $$v
                          },
                          expression: "drawerFilter",
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("v-treeview", {
        ref: "drawerTreeRef",
        staticClass: "asyent-tv",
        attrs: {
          "indeterminate-icon": "",
          open:
            _vm.drawerFilter && _vm.drawerFilter.length > 0
              ? _vm.menuLibrary
              : [],
          transition: "",
          items: _vm.menuLibrary,
          activatable: "",
          selectable: false,
          "item-key": "id",
          "multiple-active": false,
          "open-on-click": "",
          "expand-icon": "",
          "return-object": "",
        },
        scopedSlots: _vm._u([
          {
            key: "prepend",
            fn: function (ref) {
              var item = ref.item
              var open = ref.open
              return [
                _c(
                  "v-avatar",
                  {
                    staticClass: "mb-2 mt-2",
                    attrs: { color: "drawerAvatar", size: "40" },
                  },
                  [
                    item.icon
                      ? _c(
                          "v-icon",
                          {
                            attrs: {
                              size: _vm.appConfig.navigation.iconSize,
                              color: _vm.renderColor(item, _vm.color1),
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(!open ? item.icon : "mdi-folder-open") +
                                " "
                            ),
                          ]
                        )
                      : _c(
                          "v-icon",
                          {
                            attrs: {
                              color: _vm.renderColor(item, _vm.color1),
                              size: _vm.appConfig.navigation.iconSize,
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  open ? "mdi-folder-open" : "mdi-folder"
                                ) +
                                " "
                            ),
                          ]
                        ),
                  ],
                  1
                ),
              ]
            },
          },
          {
            key: "label",
            fn: function (ref) {
              var item = ref.item
              return [
                item && item.target && item.target.to
                  ? _c(
                      "router-link",
                      {
                        staticClass: "tvlink",
                        attrs: { to: item.target.to, tag: "a" },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.ae$prefixLng(
                                _vm.mI18n(item) + "._library",
                                item["shortName"]
                                  ? item["shortName"]
                                  : item["name"]
                              )
                            ) +
                            " "
                        ),
                      ]
                    )
                  : item && item.target && item.target.href
                  ? _c(
                      "a",
                      {
                        staticClass: "tvlink",
                        attrs: { href: item.target.href },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.ae$prefixLng(
                                _vm.mI18n(item) + "._library",
                                item["shortName"]
                                  ? item["shortName"]
                                  : item["name"]
                              )
                            ) +
                            " "
                        ),
                      ]
                    )
                  : item && item.url
                  ? _c(
                      "router-link",
                      {
                        staticClass: "tvlink",
                        attrs: { to: item.url, tag: "a" },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.ae$prefixLng(
                                _vm.mI18n(item) + "._library",
                                item["shortName"]
                                  ? item["shortName"]
                                  : item["name"]
                              )
                            ) +
                            " "
                        ),
                      ]
                    )
                  : _c("span", { staticClass: "tvlink" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.ae$prefixLng(
                              _vm.mI18n(item) + "._library",
                              item["shortName"]
                                ? item["shortName"]
                                : item["name"]
                            )
                          ) +
                          " "
                      ),
                    ]),
              ]
            },
          },
        ]),
        model: {
          value: _vm.tree,
          callback: function ($$v) {
            _vm.tree = $$v
          },
          expression: "tree",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }