var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-footer",
    {
      staticClass: "text-truncate text-no-wrap",
      attrs: { app: "", color: "primary", dark: "" },
    },
    [
      _c("span", { staticClass: "text-caption text-truncate text-no-wrap" }, [
        _vm._v(
          _vm._s(_vm.ae$lng("footer.organization")) +
            " © " +
            _vm._s(_vm.copyrightYear) +
            " — " +
            _vm._s(_vm.ae$lng("footer.statement"))
        ),
      ]),
      _c("v-spacer", { staticClass: "hidden-sm-and-down" }),
      _c(
        "span",
        {
          staticClass:
            "hidden-sm-and-down text-caption text-truncate text-no-wrap",
        },
        [
          _vm._v(_vm._s(_vm.ae$lng("footer.implementedBy")) + " "),
          _c("b", [_vm._v("UNCTAD/DTL/ASYCUDA")]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }