var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.noRender
    ? _c(
        "v-card",
        _vm._g(
          _vm._b(
            {
              class: { "task-card": true, "fill-height": !_vm.noFillHeight },
              attrs: { flat: "", outlined: "" },
            },
            "v-card",
            _vm.$attrs,
            false
          ),
          _vm.$listeners
        ),
        [
          _vm._t(
            "title",
            function () {
              return [
                _c(
                  "v-card-title",
                  {
                    on: {
                      dblclick: function ($event) {
                        _vm.expanded = !_vm.expanded
                      },
                    },
                  },
                  [
                    _c(
                      "v-list-item",
                      {
                        staticClass: "pl-0 pr-0",
                        staticStyle: { "max-width": "100%" },
                      },
                      [
                        _vm.icon && _vm.icon.length > 0
                          ? _c(
                              "v-list-item-avatar",
                              {
                                staticClass: "mr-6",
                                attrs: { left: "", color: _vm.avatarColor },
                              },
                              [
                                _c(
                                  "v-icon",
                                  { attrs: { dark: !_vm.avatarLight } },
                                  [_vm._v(_vm._s(_vm.icon))]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "v-list-item-content",
                          [
                            _vm.translatedSubtitle &&
                            _vm.translatedSubtitle.length > 0
                              ? _c("v-list-item-subtitle", {
                                  class:
                                    "font-weight-light caption text-truncate " +
                                    _vm.subtitleClass,
                                  domProps: {
                                    textContent: _vm._s(_vm.translatedSubtitle),
                                  },
                                })
                              : _vm._e(),
                            _vm.translatedTitle &&
                            _vm.translatedTitle.length > 0
                              ? _c("v-list-item-title", {
                                  class:
                                    "title font-weight-medium text-body-1 text-truncate " +
                                    _vm.titleClass,
                                  domProps: {
                                    textContent: _vm._s(_vm.translatedTitle),
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                        _c(
                          "v-list-item-action",
                          [
                            _c(
                              "v-tooltip",
                              {
                                attrs: { bottom: "" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function (ref) {
                                        var ton = ref.on
                                        return [
                                          _c(
                                            "v-btn",
                                            _vm._g(
                                              {
                                                attrs: {
                                                  "aria-label": _vm.expanded
                                                    ? _vm.ae$lng(
                                                        "card.collapse"
                                                      )
                                                    : _vm.ae$lng("card.expand"),
                                                  icon: "",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    _vm.expanded = !_vm.expanded
                                                  },
                                                },
                                              },
                                              ton
                                            ),
                                            [
                                              _vm.expanded
                                                ? _c("v-icon", [
                                                    _vm._v("mdi-chevron-up"),
                                                  ])
                                                : _vm._e(),
                                              !_vm.expanded
                                                ? _c("v-icon", [
                                                    _vm._v("mdi-chevron-down"),
                                                  ])
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  3457393433
                                ),
                              },
                              [
                                _vm.expanded
                                  ? _c("span", [
                                      _vm._v(
                                        _vm._s(_vm.ae$lng("card.collapse"))
                                      ),
                                    ])
                                  : _vm._e(),
                                !_vm.expanded
                                  ? _c("span", [
                                      _vm._v(_vm._s(_vm.ae$lng("card.expand"))),
                                    ])
                                  : _vm._e(),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            { title: _vm.translatedTitle, subtitle: _vm.translatedSubtitle }
          ),
          _vm.expanded ? _c("v-card-text", [_vm._t("default")], 2) : _vm._e(),
        ],
        2
      )
    : _c("div", [_vm._t("default")], 2)
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }